$(function () {
    'use strict';

    var submitBtn = $('#submit_btn'),
        formFields = $('.frm'),
        emailField = $('#email'),
        loading = $('#loading'),
        sbusc_msg = $('#sbusc_msg'),
        contactObj = {
            url: submitBtn.data('url'),
            beforeSend: function () {
                loading.removeClass('invisible')
            },
            dataComplete: function (resp) {
var alert
                if (resp.status == 2) {
                    alert = newAlert(sbusc_msg.data('exists'))
                    sbusc_msg.addClass('in')
                        .append(alert)
                }
                if (resp.status == 1) {
                    emailField.val('')
                    alert = newAlert(sbusc_msg.data('ok'))
                    sbusc_msg.addClass('in')
                        .append(alert)
                }

            },
            always: function () {
                formFields.prop('disabled', false)
                loading.addClass('invisible')

            }
        }


    function newAlert (msg) {
        return $('<div />', {
            "role": 'alert',
            "class": 'alert clearfix alert-warning alert-dismissible fade in',
            "html": '<span class="msg">'+msg+'</span><button type="button" class="close btn btn-default" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>'
        })
    }
    submitBtn.on('click', function () {

        if (!emailField.val()) {
            return;
        }
        contactObj.data = {
            email: emailField.val(),
            _token: $('meta[name="csrf-token"]').attr('content'),
            _method: 'PUT',
        }
        CallPage(contactObj);
    });
});